<!--
 * @Description: 退订规则
 * @Author: 琢磨先生
 * @Date: 2022-05-26 15:28:22
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-02 10:34:24
-->


<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增规则</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-table :data="tableData">
      <el-table-column type="expand">
        <template #default="scope">
          <el-image :src="scope.row.pic_url"></el-image>
        </template>
      </el-table-column>
      <!-- <el-table-column label="编号" prop="id" width="80"> </el-table-column> -->
      <el-table-column label="名称" prop="name" width="180"> </el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_top">停用</el-tag>
          <el-tag type="success" v-if="!scope.row.is_top">正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="入住前n天m点前" width="180">
        <template #default="scope">
          {{
            scope.row.before_day == 0
              ? `入住当天${scope.row.time}前`
              : `入住前${scope.row.before_day}天${scope.row.time}前`
          }}
        </template>
      </el-table-column>
      <el-table-column label="退款方式" prop="refund_way_text" width="140">
      </el-table-column>
      <el-table-column label="之后退订扣款" prop="deduct_way_text" width="140">
      </el-table-column>
      <el-table-column label="创建时间" prop="create_at"> </el-table-column>
      <el-table-column label="操作" width="140">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-rule :item="current" :reload="loadData"></edit-rule>
</template>
<script>
import EditRule from "./edit_rule.vue";

export default {
  components: {
    EditRule,
  },
  data() {
    return {
      activeName: "bed",
      current: null,
      tableData: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.$http.post("admin/v1/unsubscribe").then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },

    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("admin/v1/unsubscribe/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
  },
};
</script>